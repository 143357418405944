import { useTranslation } from 'react-i18next';
import Body from '../components/Body';
import { Helmet } from 'react-helmet-async';
import { Alert, AlertTitle, Box, Button, Typography, keyframes } from '@mui/material';
import MarkdownText from '../components/MarkdownText';
import { useNavigate } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import { useCallback } from 'react';
import Image from '../components/Image';
import Section from '../components/Section';
import { Form } from 'react-final-form';
import TextField from '../components/fields/TextField';
import CheckboxField from '../components/fields/CheckboxField';
import { required } from '../components/fields/helpers';
import client from '../data/client';
import LeadMutation from '../data/mutations/LeadMutation';
import useLocalStorage from '../helpers/useLocalStorage';

const highlight = keyframes`
  0% { transform: scale(1); }
  25% { transform: scale(1.05); }
  50% { transform: scale(1); }
  75% { transform: scale(1.05); }
  100% { transform: scale(1); }
`;

/**
 * Native scrollTo with callback
 * @param offset - offset to scroll to
 * @param callback - callback function
 */
function scrollTo(offset: number, callback: () => void) {
  const fixedOffset = offset.toFixed();
  const onScroll = function () {
          if (window.pageYOffset.toFixed() === fixedOffset) {
              window.removeEventListener('scroll', onScroll)
              callback()
          }
      }

  window.addEventListener('scroll', onScroll)
  onScroll()
  window.scrollTo({
      top: offset,
      behavior: 'smooth'
  })
}

export default function Training() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const pageTitle = t('training.page_title', 'Hemligheten till kärleken: 4 steg till din drömpartner');
  const caption = t('training.caption', 'Våra matchmakers avslöjar');
  const lead = t('training.lead', 'Hemligheten till kärleken');
  const title = t('training.title', '4 steg till din drömpartner');
  const description = t('training.description', 'Ta del av vår exklusiva träning och lär dig våra professionella matchmakers bästa tips om vad DU behöver göra för att hitta kärleken som du längtar efter och förtjänar.');

  const negatives = [
    {
      title: t('training_negative_1.title', 'Vara fast i dejtingapparna'),
    },
    {
      title: t('training_negative_2.title', 'Skjuta upp ditt dejtande till framtiden'),
    },
    {
      title: t('training_negative_4.title', 'Upprepa samma mönster om och om igen'),
    },
    {
      title: t('training_negative_5.title', 'Vara omedveten om vem du bör träffa'),
    },
    {
      title: t('training_negative_6.title', 'Slösa din tid genom att fortsätta dejta fel personer'),
    }
  ];

  const positives = [
    {
      title: t('training_positive_1.title', 'Förstå exakt vad som håller dig tillbaka från kärleken idag'),
    },
    {
      title: t('training_positive_2.title', 'Bli medveten om vem du behöver träffa'),
    },
    {
      title: t('training_positive_4.title', 'Dejta på rätt sätt som attraherar rätt personer till dig'),
    },
    {
      title: t('training_positive_5.title', 'Träffa personerna som faktiskt kan bygga en framtid med'),
    },
    {
      title: t('training_positive_6.title', 'Maximera dina möjligheter att träffa din person'),
    }
  ];

  
  const features = [
    {
      name: 'SVD',
      width: 132 / 2,
      icon: '/svd.png',
    },
    {
      name: 'Dagens Nyheter',
      width: 115 / 2,
      icon: '/dn.png',
    },
    {
      name: 'Elle',
      width: 138 / 2,
      icon: '/elle.png',
    },
    {
      name: 'Breakit',
      width: 205 / 2,
      icon: '/breakit.png',
    },
    {
      name: 'Financial Times',
      width: 464 / 2,
      icon: '/ft.png',
      fill: true,
    },
    {
      name: 'SVT',
      width: 112 / 2,
      icon: '/svt.png',
    },
    {
      name: 'DI Weekend',
      width: 211 / 2,
      icon: '/diweekend.png',
    },
    {
      name: 'Må Bra',
      width: 211 / 2,
      icon: '/mabra.png',
    },
    {
      name: 'BBC',
      width: 192 / 2,
      icon: '/bbc.png',
    },
    {
      name: 'Sveriges Radio',
      width: 350 / 2,
      icon: '/sr.png',
      fill: true,
    },
  ];

  const [, setFormValues] = useLocalStorage('training-form', {});

  const onSubmit = useCallback(async ({ accepted, ...fields }) => {
    if (accepted) {
      setFormValues(fields);
      const res = await client.mutate({
        mutation: LeadMutation,
        variables: { fields },
      });

      if (res.data.lead === true) {
        navigate('/training-video');
      }
    }
  }, []);

  const highlightDuration = 350;
  const handleClick = useCallback(() => {
    const el = document.getElementById('form');
    if (el) {
      // center form in window.
      const top = el.getBoundingClientRect().top + window.scrollY - (window.innerHeight - el.offsetHeight) / 2;
      scrollTo(top, () => {
        el.classList.add('highlight');
        // clear the className to enable re-running the animation.
        setTimeout(() => { el?.classList.remove('highlight'); }, highlightDuration);
      });
    }
  }, []);

  return (
    <Box
      sx={{
        backgroundColor: '#38191e',
      }}
    >
      <Helmet>
        <title>Relate | {pageTitle}</title>
      </Helmet>
      <Box sx={{ background: '#9C4452', textAlign: 'center', p: 1, display: { xs: 'none', md: 'block' }, }}>
        <Typography component="h5" variant="h5" sx={{ color: '#fff', textTransform: 'uppercase', fontFamily: '"DM Sans", sans-serif', fontSize: 24, fontWeight: 700 }}>{caption}</Typography>
      </Box>
      <Box sx={{
        display: 'flex',
        flexDirection: { xs: 'column', md: 'row-reverse' },
        maxWidth: 1480,
        margin: '0 auto',
      }}>
        <Box sx={{
          flex: 1,
          display: { xs: 'flex', md: 'none' },
          flexDirection: 'column',
        }}>
          <Box
            component="img"
            src="/christina-large.png"
            sx={{
              width: '100%',
            }}
            title="Christina"
          />
          <Image src="/logo.png" sx={{ width: 80, position: 'absolute', top: 16, left: 'calc((100% - 80px) / 2)' }} />
          <Box sx={{ background: '#9C4452', textAlign: 'center', p: 1 }}>
            <Typography component="h5" variant="h5" sx={{ color: '#fff', textTransform: 'uppercase', fontFamily: '"DM Sans", sans-serif', fontWeight: 700 }}>{caption}</Typography>
          </Box>
        </Box>
        <Box sx={{
          flex: 1,
          display: { xs: 'none', md: 'flex' },
          justifyContent: 'center',
          flexDirection: 'column',
          overflow: 'hidden',
        }}>
          <Box
            sx={{
              width: '100%',
              height: '100%',
              backgroundImage: 'url(/christina-full.png)',
              backgroundSize: 'cover',
              backgroundPositionY: '25%',
            }}
            title="Christina"
          />
        </Box>
        <Box sx={{ flex: 1 }}>
          <Box
            sx={{
              px: { xs: 3, sm: 6 },
              py: { xs: 4, sm: 6 },
              pb: { xs: 8, sm: 12 },
              textAlign: 'center',
              em: { color: 'white', fontWeight: 'bold' },
            }}
          >
            <Image src="/logo.png" sx={{ width: 100, mb: 4, display: { xs: 'none', md: 'inline' } }} />
            <Box sx={{ pb: 2 }}>
              <Typography component="h5" variant="h5" sx={{
                mb: 2, color: '#BF9197', textTransform: 'uppercase', fontFamily: '"DM Sans", sans-serif', fontWeight: 700, fontSize: { xs: 20, md: 28 }
              }}>{lead}</Typography>
              <Typography component="h1" variant="h1" sx={{ mb: 3, textTransform: 'uppercase', fontSize: { md: 80 } }}>{title}</Typography>
              <Typography variant="body1">{description}</Typography>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box sx={{ background: '#9C4452', textAlign: 'center', p: 0.5 }}></Box>
      <Body>
        <Box>
          <Box id="form" sx={{
            background: '#38191E',
            border: 'solid 4px #8E603C88',
            borderRadius: { xs: 4, md: 8 },
            mx: { xs: 2, md: 0 },
            mb: 0,
            position: 'relative',
            top: -64,
            p: { xs: 2, md: 4 },
            '&.highlight': {
              animation: `${highlightDuration}ms ease forwards ${highlight}`,
            },
          }}>
            <Form onSubmit={onSubmit}>
              {({
                submitting,
                submitError,
                hasValidationErrors,
                hasSubmitErrors,
                submitSucceeded,
                dirty,
                handleSubmit,
              }) => (
                <form onSubmit={handleSubmit}>
                  <TextField
                    name="lastName"
                    label={t(`last_name.label`, 'Efternamn')}
                    placeholder={t(`last_name.placeholder`, 'Ditt efternamn')}
                    autoComplete="off"
                    autoCorrect="off"
                    spellCheck="false"
                    sx={{ height: 0, overflow: 'hidden', position: 'absolute' }}
                  />
                  <TextField
                    name="firstName"
                    required
                    label={t(`first_name.label`, 'Förnamn')}
                    placeholder={t(`first_name.placeholder`, 'Ditt förnamn')}
                    autoComplete="off"
                    autoCorrect="off"
                    spellCheck="false"
                  />
                  <TextField
                    name="email"
                    required
                    label={t(`email.label`, 'Email')}
                    placeholder={t(`email.placeholder`, 'Din email')}
                    type="email"
                    autoComplete="off"
                    autoCorrect="off"
                    autoCapitalize="none"
                    spellCheck="false"
                  />
                  <CheckboxField
                    sx={{ pt: 1, pb: 2, mx: 0 }} 
                    name="accepted"
                    label={t('accept_contact_terms_and_conditions', 'Godkänn att vi kan kontakta dig enligt våra [villkor](https://relate-matchmaking.com/terms) och [policy](https://relate-matchmaking.com/privacy).')}
                    config={{ validate: required }}
                    componentsProps={{
                      typography: {
                        component: MarkdownText,
                      },
                    }}
                  />
                  {submitError && (
                    <Alert severity="error" sx={{ mt: 2, mb: 2, borderRadius: 4 }}>
                      <AlertTitle>{t('error_title', 'Något gick fel...')}</AlertTitle>
                      {submitError}
                    </Alert>
                  )}
                  <Button
                    fullWidth
                    size="large"
                    variant="contained"
                    type="submit"
                    disabled={submitting || hasValidationErrors || ((hasSubmitErrors || submitSucceeded) && !dirty)}
                    sx={{
                      my: 1,
                      backgroundAttachment: 'fixed',
                      backgroundImage:  'repeating-radial-gradient(circle at 400px -100px, #76451F66 0, transparent 800px), repeating-linear-gradient(#be8a6144, #be8a6122)',
                      textTransform: 'uppercase',
                    }}
                  >
                    {t('training.action', 'Få gratis träning')}
                  </Button>
                </form>
              )}
            </Form>
          </Box>
          <Section highlight>
            <Typography component="h3" variant="h3" textAlign="center">
              {t('training_negatives.title', 'Du kan:')}
            </Typography>
            <Box sx={{ textAlign: 'center', mb: 4 }}>
              {negatives.map((item) => (
                <Box key={item.title} sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', my: 1 }}>
                  <Box
                    sx={{
                      width: 32, height: 32, minWidth: 32, mr: 2,
                      borderRadius: 16,
                      border: 'solid 2px #DD2544',
                      color: '#DD2544',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <CloseIcon fontSize="small" />
                  </Box>
                  <Box sx={{ display: 'flex', flexDirection: 'column', textAlign: 'left', justifyContent: 'center', alignItems: 'center' }}>
                    <Typography variant="body2" sx={{ mb: 0, color: '#fff' }}>{item.title}</Typography>
                  </Box>
                </Box>
              ))}
            </Box>
            <Typography component="h3" variant="h3" textAlign="center">
              {t('training_positives.title', '...eller så kan du:')}
            </Typography>
            <Box sx={{ textAlign: 'center' }}>
              {positives.map((item) => (
                <Box key={item.title} sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', my: 1 }}>
                  <Box
                    sx={{
                      width: 32, height: 32, minWidth: 32, mr: 2,
                      borderRadius: 16,
                      border: 'solid 2px #5EA02E',
                      color: '#5EA02E',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <CheckIcon fontSize="small" />
                  </Box>
                  <Box sx={{ display: 'flex', flexDirection: 'column', textAlign: 'left', justifyContent: 'center', alignItems: 'center' }}>
                    <Typography variant="body2" sx={{ mb: 0, color: '#fff' }}>{item.title}</Typography>
                  </Box>
                </Box>
              ))}
            </Box>
          </Section>
          <Section>
            <Box sx={{ textAlign: 'left', mb: 4, }}>
              <Typography component="h2" variant="h2" textAlign="left" sx={{ mb: 3 }}>
                {t('training.sales_title', 'Det är inte konstigt om du fortfarande letar efter din drömpartner')}
              </Typography>
              <MarkdownText>
                {t('training.sales', `Vi förstår – att dejta och hitta sin livspartner är svårare än någonsin idag.\n\nDejtingapparna kryllar av oseriösa personer som inte är redo för relationen du vill bygga. Och även om din person finns där så kan det kännas som att leta efter en nål i en höstack. Ett ändlöst svajpande genom bilder som säger dig väldigt lite om den faktiska personen bakom profilen. Det är inte så konstigt om detta lämnar dig frustruerad och i värsta fall... Hopplös.\n\nMen tänk om vi sa att det finns ett annat sätt. Ett sätt där vägen till din livspartner inte känns som en gåta eller ständig uppförsbacke. \n\nEtt sätt där du har en tydlig steg-för-steg process som tar dig på den snabbaste vägen till din drömrelation.\n\nDet finns ett annat sätt. Vår beprövade process har hjälpt tusentals singlar – från att längta efter kärleken, till att vara i relationen de alltid drömt om.\n\nOch nu kan det bli din tur, men det är helt upp till dig. Vi vet att vår process funkar – du behöver bara ta det första steget.`)}
              </MarkdownText>
            </Box>
            <Box sx={{
              background: '#38191E',
              borderRadius: { xs: 4, sm: 8 },
              mb: { xs: 3, sm: 4 },
              p: { xs: 2, sm: 4 },
              textAlign: 'center',
            }}>
              <MarkdownText>
                {t('training.sales_focus', '#### Det har fungerat för tusentals.\n #### Det kan fungera för dig med.')}
              </MarkdownText>
            </Box>
            <Button
              fullWidth
              size="large"
              variant="contained"
              type="submit"
              onClick={handleClick}
              sx={{
                my: 1,
                backgroundAttachment: 'fixed',
                backgroundImage:  'repeating-radial-gradient(circle at 400px -100px, #76451F66 0, transparent 800px), repeating-linear-gradient(#be8a6144, #be8a6122)',
                textTransform: 'uppercase',
              }}
            >
              {t('training.sales_action', 'Starta med gratis träning')}
            </Button>
          </Section>
          <Section highlight>
            <Box sx={{ textAlign: 'left', mb: 4, }}>
              <Typography component="h2" variant="h2" textAlign="center" sx={{ mb: 3 }}>
                {t('training.about_title', 'Om oss')}
              </Typography>
              <MarkdownText>
                {t('training.about', `Tillsammans med en av världens ledande matchmakingbyråer Lemarc Thomas, hjälper vi på Relate DIG som längtar efter kärleken. Som inte vill vara fast i apparna och som inte längre vill vänta.\n\nVi har hjälp tusentals singlar till kärleken och vi kan hjälpa DIG med samma sak. I vår träning delar vi några av våra bästa tips för hur du hittar rätt i dagens dejting-djungel.\n\nVi vill att du ska lyckas nå din drömrelation. Du kanske undrar varför? Vår övertygelse är att alla förtjänar kärlek. Det spelar ingen roll hur dina tidigare relationer sett ut, hur du känner dig älskad eller hur dina förutsättningar för kärlek ser ut idag. Du, precis som alla andra, förtjänar en trygg, kärleksfull och hälsosam relation – med en person som älskar dig för den du är.\n\nDessutom är vårt jobb bokstavligen att hjälpa personer som dig att just hitta kärleken. I våra program hjälper våra dedikerade matchmakers personer hela vägen till kärleken och sin match.\n\nVem vet, efter du har tagit del av vår träning och sett hur vår process för att hitta och skapa kärlek går till, så kanske du också önskar att ha din personliga kärleksexpert vid din sida som stöttar och guidar dig till drömrelationen.\n\nOavsett om du bara vill ta del av vår träning för att öka dina chanser till kärlek på egen hand, eller om det visar sig att du är intresserad av att jobba tillsammans med oss, så vill vi dela med oss av vår kunskap och expertis med så många som möjligt.\n\nSå att fler personer hittar den kärlek som de längtar efter och förtjänar.`)}
              </MarkdownText>
            </Box>
            <Button
              fullWidth
              size="large"
              variant="contained"
              type="submit"
              onClick={handleClick}
              sx={{
                my: 1,
                backgroundAttachment: 'fixed',
                backgroundImage:  'repeating-radial-gradient(circle at 400px -100px, #76451F66 0, transparent 800px), repeating-linear-gradient(#be8a6144, #be8a6122)',
                textTransform: 'uppercase',
              }}
            >
              {t('training.about_action', 'Titta på vår gratis träning nu')}
            </Button>
          </Section>
          <Section>
            <Typography component="h6" variant="h6" textAlign="center" textTransform="uppercase" fontStyle="italic" fontFamily="'DM Sans', sans-serif">
              {t('featured_in.title', 'Featured i')}
            </Typography>
            <Box sx={{
              display: 'flex',
              flexDirection: { xs: 'column', sm: 'row' },
            }}>
              <Box sx={{ textAlign: 'center', display: 'flex', flexDirection: 'row', flexWrap: 'wrap', maxWidth: 320, margin: '0 auto' }}>
                {features.slice(0, 5).map((item) => (
                  <Box
                    key={item.name} 
                    sx={{
                      flex: 1,
                      flexBasis: item?.fill ? '100%' : '50%',
                      width: item?.fill ? '100%' : '50%',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      height: 80,
                    }}
                  >
                    <Box
                      component="img"
                      src={item.icon}
                      sx={{
                        width: item.width,
                      }}
                      title={item.name}
                    />
                  </Box>
                ))}
              </Box>
              <Box sx={{ textAlign: 'center', display: 'flex', flexDirection: 'row', flexWrap: 'wrap', maxWidth: 320, margin: '0 auto' }}>
                {features.slice(5).map((item) => (
                  <Box
                    key={item.name} 
                    sx={{
                      flex: 1,
                      flexBasis: item?.fill ? '100%' : '50%',
                      width: item?.fill ? '100%' : '50%',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      height: 80,
                    }}
                  >
                    <Box
                      component="img"
                      src={item.icon}
                      sx={{
                        width: item.width,
                      }}
                      title={item.name}
                    />
                  </Box>
                ))}
              </Box>
            </Box>
          </Section>
          <Section highlight sx={{ alignItems: 'center', flexDirection: 'row', my: 2 }}>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <Typography variant="body2" textAlign="center" fontStyle="italic" sx={{ mb: 2 }}>
                {t('training.quote', 'Det känns så himla förstklassigt och otroligt bra stöd. Jag var inte beredd på alla de insikter som skulle komma från övningarna, jag känner mig typ som en helt ny människa. Mina kompisar säger att jag pratar på ett sätt som jag aldrig har gjort förut. Det har överträffat allt, det är så häftigt verkligen!')}
              </Typography>
              <Typography component="h5" variant="h5" textAlign="center">
                {t('training.quote_author', '– {{name}}, {{age}} år', { name: 'Madeleine', age: 34 })}
              </Typography>
            </Box>
          </Section>
          <Section highlight sx={{ alignItems: 'center', flexDirection: 'row' }}>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <Typography variant="body2" textAlign="center" fontStyle="italic" sx={{ mb: 2 }}>
                {t('training.quote2', 'Jag förstår nu att personerna jag dejtade innan programmet var så långt från vem jag faktiskt söker. Denna upplevelse har hjälpt mig att inse vem jag verkligen behöver och vill ha i mitt liv. Jag har uppskattat min matchmakers lyhördhet för vad jag vill ha samtidigt som jag har blivit utmanad i det. Jag tyckte att varje introduktion kändes närmare och närmare den rätta och idag träffar jag en tjej där jag känner att det finns något att bygga en framtid på.')}
              </Typography>
              <Typography component="h5" variant="h5" textAlign="center">
                {t('training.quote2_author', '– {{name}}, {{age}} år', { name: 'Emil', age: 36 })}
              </Typography>
            </Box>
          </Section>
          <Section sx={{ alignItems: 'center' }}>
            <Image src="/logo.png" sx={{ width: 84 }} />
            <Typography variant="caption">
              {t('slogan')}
            </Typography>
          </Section>
          <Section />
        </Box>
      </Body>
    </Box>
  );
}
